<template>
  <div>
    <ImportedItemsDataTable
      :single-select="false"
      :height="dataTableHeight"
      :show-select="false"
      item-key="id"
      fixed-header
      class="mt-1"
      :items="localItems"
      :headers="headers"
      :page.sync="page"
      :row-per-page.sync="rowPerPage"
      :total-pages.sync="totalPages"
      :show-actions="true"
    >
      <template v-slot:custom-createdAtTime="{ item }">
        <DateTimeFormattor
          :with-time="true"
          :with-date="false"
          v-model="item['createdAt']"
          not-specified-text="Non specificato"
        ></DateTimeFormattor>
      </template>

      <template v-slot:custom-type="{ item }">
        <v-icon
          class="d-flex flex-wrap aling-center justify-center"
          v-if="item['type'] === 'upload'"
          color="success"
        >
          mdi-package-up</v-icon
        >
        <v-icon
          class="d-flex flex-wrap aling-center justify-center"
          v-else-if="item['type'] === 'unload'"
          color="error"
        >
          mdi-dolly</v-icon
        >
      </template>
      <template v-slot:actions="{ item }">
        <div style="min-width: 100px">
          <v-btn icon class="ml-1" @click="detailMovement(item)">
            <v-icon> mdi-package-up </v-icon>
          </v-btn>
        </div>
      </template>
    </ImportedItemsDataTable>
  </div>
</template>

<script>
import ImportedItemsDataTable from "@/components/common/TypeDataTable.vue";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";
import movementService from "@/services/warehouse/movements.service.js";
import Vue from "vue";

export default {
  name: "MovementList",
  components: {
    ImportedItemsDataTable,
    DateTimeFormattor,
  },
  props: {
    dataTableHeightValue: { type: Number, default: 210 },
    routeFather:{
      type:String,
      default:''
    },
    busFilters: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
    filters: {
      type: Array | Object,
      default: () => []
    }
  },
  data() {
    return {
      page: 1,
      rowPerPage: 20,
      totalPages: 2,
      localFilters: undefined,
      localItems: [],
      headers: [
        {
          text: "Carico/Scarico",
          value: "type",
          type: "custom",
        },
        {
          text: "Codice Interno Movimento",
          value: "code",
        },
        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Nome File",
          value: "fileName",
        },
        {
          text: "Estensione",
          value: "extension",
        },
        {
          text: "Ora Creazione",
          value: "createdAtTime",
          type: "custom",
        },
        {
          text: "Data Creazione",
          value: "createdAt",
          type: "date",
        },
        {
          text: "Prodotti totali da importare",
          value: "countItems",
        },
        {
          text: "Prodotti importati",
          value: "countItemsImported",
        },
        {
          text: "Prodotti in errore",
          value: "countItemsInError",
        },
        {
          text: "Completata",
          value: "allRowsImported",
          type: "bool",
        },
      ],
    };
  },
  mounted(){
    this.fetchItems(this.filters)
    this.busFilters.$on('new-filters',this.fetchItems)

  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  methods: {
    detailMovement(movement) {
      let params;
      let routeName;
      if (!!movement.orderId) {
        routeName = "MovementOrderDetail";

        params = {
          orderId: movement.orderId,
        };
      } else if (!!movement.importId) {
        routeName = "MovementImportDetail";
        params = {
          importId: movement.importId,
        };
      } else if (
        (!!movement.movementId && !movement.orderId) ||
        !movement.importId
      ) {
        routeName = "MovementScannerDetail";
        params = {
          movementId: movement.movementId,
        };
      }
      this.$router.push({
        name: routeName,
        params: {...params, filters: this.localFilters},
        query: {
          pathToGoBack: this.$router.resolve({ name: this.routeFather }).href,
          pathName: this.routeFather,
        },
      });
    },
    fetchItems(filters){
      this.localFilters = filters
      movementService
        .list(this.page, this.rowPerPage, filters)
        .then((movementUploadList) => {
          this.totalPages = movementUploadList.totalPages
          this.localItems = movementUploadList.rows.map((movement) => {
            if (movement.import)
              return {
                movementId: movement.id,
                importId: movement.importId,
                code: movement.code,
                description: movement.description,
                type: movement.type,
                fileName: movement.import.fileName,
                extension: movement.import.extension,
                createdAtTime: movement.import.createdAt,
                createdAt: movement.import.createdAt,
                countItems: movement.import.items.length,
                countItemsImported: movement.import.itemsImported.length,
                countItemsInError: movement.import.itemsInError.length,
                allRowsImported: movement.import.allRowsImported,
              };
            else
              return {
                movementId: movement.id,
                code: movement.code,
                description: movement.description,
                type: movement.type,
                createdAt: movement.createdAt,
                countItems: movement.items.length,
                countItemsImported: movement.items.length,
                countItemsInError: 0,
                allRowsImported: true,
                createdAtTime: movement.createdAt,
              };
          });
        });
    },
  },
  watch: {
    page() {
      this.fetchItems(this.localFilters);
    },
    rowPerPage() {
      this.fetchItems(this.localFilters);
    },
  },
};
</script>

<style>
</style>